.App {
  /* text-align: center; */
  /* background-color: red; */
  display: flex;
  flex: 1;
  flex-direction: column;
  /* background-image: url("./bg2.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #2b8dde;
}
.tag {
  color: #fff;
  font-size: x-large;
  /* font-style: italic; */
  font-weight: 100;
}
.left {
  /* background-color: blue; */
  flex: 1;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.left > img {
  height: 75vh;
  /* background-color: aqua; */
}
.right {
  /* background-color: purple; */
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
}
.fetaures > li {
  color: white;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  /* -webkit-text-stroke: 1px rgba(0, 0, 0, 0.32); */
}
.logo {
  font-size: 5rem;
  color: white;
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo > img {
  background-color: #2b8dde;
  padding: 10px;
  height: 100px;
  margin-right: 5px;
  border-radius: 15px;
}
.logo1 {
  color: #fff;
  font-weight: 500;

  /* -webkit-text-stroke: 2px #2b8dde; */
}
.logo2 {
  color: #fff;
  font-weight: 300;
  margin-left: 5px;
  margin-right: 5px;
  /* -webkit-text-stroke: 1px white; */
}
.container {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.links {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}
.links > a {
  margin-right: 1rem;
}
.links > a > img {
  margin-right: 2rem;
  height: 64px;
}

.footer {
  margin-top: 1rem;
  margin-bottom: 2rem;
  /* background-color: rgb(235, 195, 64); */
  position: relative;
  /* bottom: 0;
  left: 0; */
  width: 100%;
  justify-content: center;
  text-align: center;
  color: #fff;
}
.footer p {
  margin: 0.5rem;
}
.footer a {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .left {
    align-items: flex-start;
    flex: 1;
  }
  .left > img {
    margin-top: 1rem;
    height: 55vh;
    align-self: flex-start;
    /* background-color: red; */
  }
  .logo {
    font-size: 3rem;
  }
  .logo > img {
    height: 40px;
  }
  /* .links {
    display: flex;
    flex-direction: column;
  } */
  .links a {
    margin-bottom: 1rem;
  }
  .links > a > img {
    height: 48px;
    margin-right: 0rem;
  }
  .links > a {
    margin: 0.5rem;
  }

  .fetaures {
    padding: 0rem;
  }
  .fetaures > li {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    line-height: 1.3rem;
  }
  .right {
    align-items: center;
  }
  .video-responsive iframe {
    max-width: 90vw;
    max-height: 56.25vw;
  }
}

.video-responsive {
  /* overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
}

.video-responsive iframe {
  width: 80%;
  height: 480px;
  /* max-width: 90vw;
  max-height: 56.25vw;
  width: 80%;
  height: 56.25vw; */
}
